import numeral from 'numeral';
import { median } from 'd3';
import { createBrowserHistory } from 'history';
import * as d3 from 'd3';

export const history = createBrowserHistory();

const trillion = 1000000000000;
const billion = 1000000000;
const million = 1000000;
const thousand = 1000;

numeral.register('locale', 'financial-canvas', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'bn',
    trillion: 't',
  },
  currency: {
    symbol: '£',
  },
});

numeral.locale('financial-canvas');

export const format = (num, format) => numeral(num).format(format);

export const splitFormat = (num, boundary, formatUnder, formatOver) =>
  num > boundary
    ? numeral(num).format(formatOver)
    : numeral(num).format(formatUnder);

d3.formatDefaultLocale({
  decimal: '.',
  thousands: ',',
  grouping: [3],
  currency: ['£', ''],
});

export const d3Format = (num, format) => {
  let val = d3.format(format)(num);
  return val
    .replace(/M/, 'm')
    .replace(/G/, 'bn')
    .replace(/T/, 'tr');
};

export const difference = (a, b) => {
  const abs = Math.abs(a);
  let abbr = '';
  let value = a - b;
  let scaledValue = a - b;
  let scaledA = a;
  let scaledB = b;
  let scale = 1;

  if (abs >= trillion) {
    abbr = 't';
    scaledValue = value / trillion;
    scaledA = a / trillion;
    scaledB = b / trillion;
    scale = trillion;
  } else if (abs < trillion && abs >= billion) {
    abbr = 'b';
    scaledValue = value / billion;
    scaledA = a / billion;
    scaledB = b / billion;
    scale = billion;
  } else if (abs < billion && abs >= million) {
    abbr = 'm';
    scaledValue = value / million;
    scaledA = a / million;
    scaledB = b / million;
    scale = million;
  } else if (abs < million && abs >= thousand) {
    abbr = 'k';
    scaledValue = value / thousand;
    scaledA = a / thousand;
    scaledB = b / thousand;
    scale = thousand;
  }

  // FIXME: The library is broken. We should use something else. This gets around an issue with small numbers causing NaNs
  if (Math.abs(scaledValue) < 1e-6) {
    value = 0;
  } else {
    value = (scaledA.toFixed(1) - scaledB.toFixed(1)) * scale;
  }

  return format(value, `$0.0a${abbr}`);
};

/** Choose a single abbreviation appropriate for an array of numbers, e.g. b for billion, k for thousand */
export const findCommonAbbreviation = arr => {
  var medianValue = median(
    arr.map(function(d) {
      return Math.abs(d);
    })
  );

  var abbr = '';

  if (medianValue >= trillion) {
    abbr = 't';
  } else if (medianValue < trillion && medianValue >= billion) {
    abbr = 'b';
  } else if (medianValue < billion && medianValue >= million) {
    abbr = 'm';
  } else if (medianValue < million && medianValue >= thousand) {
    abbr = 'k';
  }

  return abbr;
};

export const currencySymbol = currencyName => {
  const currency_symbols = {
    USD: '$', // US Dollar
    EUR: '€', // Euro
    CRC: '₡', // Costa Rican Colón
    GBP: '£', // British Pound Sterling
    ILS: '₪', // Israeli New Sheqel
    INR: '₹', // Indian Rupee
    JPY: '¥', // Japanese Yen
    KRW: '₩', // South Korean Won
    NGN: '₦', // Nigerian Naira
    PHP: '₱', // Philippine Peso
    PLN: 'zł', // Polish Zloty
    PYG: '₲', // Paraguayan Guarani
    THB: '฿', // Thai Baht
    UAH: '₴', // Ukrainian Hryvnia
    VND: '₫', // Vietnamese Dong
  };

  return currency_symbols[currencyName];
};

export const colors = color => {
  const colors = {
    cyan: '#00aeef',
    mustard: '#eedc00',
    pms273: '#27318b',
    pms32: '#ef4135',
    pms376: '#8dc63f', //green
    magenta: '#ec008c',
    pms21: '#f78e1e', //orange
    darkGrey: '#6a737b', //pms431
    midGrey: '#b0b7bc', //pms429
    lightGrey: '#d0d4d7', //pms429 @60%
  };

  return colors[color];
};

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export const getFontColor = hex => {
  // grab rgb values from a hex input
  const { r, g, b } = hexToRgb(hex);
  // calculate luminance of color based on W3C formaula
  const L = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  // if L greater than 186 threshold return black else return white
  return L > 186 ? '#000000' : '#ffffff';
};
