import {
  REQUEST_DATA,
  RECEIVE_DATA,
  SELECT,
  ADD_SCENARIO,
  REMOVE_SCENARIO,
  TOGGLE_SCENARIO,
  TOGGLE_TRIGGERS,
  LOG_IN,
  LOG_IN_SUCCESS,
  LOG_IN_FAILURE,
  LOG_OUT,
  LOG_OUT_SUCCESS,
  LOG_OUT_FAILURE,
  SHOW_MAXIMIZED_CHART,
  HIDE_MAXIMIZED_CHART,
  SEND_MODEL_PARAMETER,
  UPDATE_DASHBOARD,
  UPDATE_SOURCE,
  UPDATE_SCENARIO_NAME,
} from '../actions';
import ReactGA from 'react-ga';
import config from '../config.json';

import { schemeCategory10 } from 'd3-scale-chromatic';

const colors = schemeCategory10.slice().reverse();

const defaultDashboard =
  config.environments[process.env.REACT_APP_ENVIRONMENT].dashboards[0];

const defaultState = {
  triggersEnabled: true,
  data: {},
  auth: {
    user: null,
    isLoggingIn: false,
    logInError: null,
    redirect: false,
  },
  dashboard: defaultDashboard,
  source: config.dashboards[defaultDashboard].source,
  config: config.environments[process.env.REACT_APP_ENVIRONMENT].dashboards.map(
    d => config.dashboards[d]
  ),
  enableAuth:
    config.environments[process.env.REACT_APP_ENVIRONMENT].ENABLE_AUTH,
  enablePopup:
    config.environments[process.env.REACT_APP_ENVIRONMENT].ENABLE_POPUP,
  showMaximizedChart: false,
  tasks: [],
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SELECT:
      ReactGA.event({
        category: action.dashboard,
        action: action.key,
        label: JSON.stringify(action.value),
      });

      return {
        ...state,
        [action.dashboard]: {
          ...state[action.dashboard],
          [action.key]: action.value,
        },
      };
    case ADD_SCENARIO:
      return {
        ...state,
        [action.dashboard]: {
          ...state[action.dashboard],
          scenarios: [
            ...(state[action.dashboard] === undefined
              ? []
              : state[action.dashboard].scenarios === undefined
                ? []
                : state[action.dashboard].scenarios),
            {
              name: `Scenario ${action.id}`,
              id: action.id,
              color: colors[action.id % colors.length],
              ...action.content,
            },
          ],
        },
      };
    case REMOVE_SCENARIO:
      if (
        state[action.dashboard] === undefined ||
        state[action.dashboard].scenarios === undefined
      )
        return { ...state };

      return {
        ...state,
        [action.dashboard]: {
          ...state[action.dashboard],
          scenarios: [
            ...state[action.dashboard].scenarios.filter(
              d => d.name !== action.id
            ),
          ],
        },
      };
    case TOGGLE_SCENARIO:
      if (
        state[action.dashboard] === undefined ||
        state[action.dashboard].scenarios === undefined
      )
        return { ...state };

      const scenarioToToggle = state[action.dashboard].scenarios.find(
        d => d.name === action.id
      );

      return {
        ...state,
        [action.dashboard]: {
          ...state[action.dashboard],
          scenarios: [
            ...state[action.dashboard].scenarios.filter(
              d => d.name !== action.id
            ),
            { ...scenarioToToggle, visible: !scenarioToToggle.visible },
          ].sort((a, b) => b.id - a.id),
        },
      };

    case UPDATE_SCENARIO_NAME:
      if (
        state[action.dashboard] === undefined ||
        state[action.dashboard].scenarios === undefined
      ) {
        return { ...state };
      }

      return {
        ...state,
        [action.dashboard]: {
          ...state[action.dashboard],
          scenarios: [
            ...state[action.dashboard].scenarios.map((d, i) => {
              if (i === action.id) {
                return { ...d, name: `${action.content}` };
              }
              return d;
            }),
          ],
        },
      };

    case REQUEST_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case RECEIVE_DATA:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case LOG_IN:
      return {
        ...state,
        auth: {
          ...state.auth,
          isLoggingIn: true,
          logInError: null,
        },
      };
    case LOG_IN_SUCCESS:
      return {
        ...state,
        auth: {
          ...state.auth,
          user: {
            email: action.email,
          },
          isLoggingIn: false,
          logInError: null,
          redirect: true,
        },
      };
    case LOG_IN_FAILURE:
      return {
        ...state,
        auth: {
          ...state.auth,
          user: null,
          isLoggingIn: false,
          logInError: action.error,
          redirect: false,
        },
      };
    case LOG_OUT:
      return {
        ...state,
        auth: {
          ...state.auth,
          redirect: false,
        },
      };
    case LOG_OUT_SUCCESS:
      return {
        ...state,
        auth: {
          ...state.auth,
          user: null,
          redirect: false,
        },
      };
    case LOG_OUT_FAILURE:
      return {
        ...state,
      };
    case TOGGLE_TRIGGERS:
      return {
        ...state,
        triggersEnabled: !state.triggersEnabled,
      };
    case SHOW_MAXIMIZED_CHART:
      return {
        ...state,
        showMaximizedChart: true,
        maximizedChart: action.chart,
      };
    case HIDE_MAXIMIZED_CHART:
      return {
        ...state,
        showMaximizedChart: false,
      };
    case SEND_MODEL_PARAMETER:
      return {
        ...state,
      };
    case UPDATE_DASHBOARD:
      return {
        ...state,
        dashboard: action.dashboard,
      };
    case UPDATE_SOURCE:
      return {
        ...state,
        source: action.source,
      };
    default:
      return state;
  }
};

export default reducer;
