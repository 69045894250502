import React from 'react';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import {
  Alert,
  Button,
  FormGroup,
  ControlLabel,
  Form,
  Col,
  FormControl,
} from 'react-bootstrap';
import styled from 'styled-components';
import { login, logout } from '../actions';

const LoginWrapper = styled.div`
  padding-top: 20%;
  padding-left: 40px;
  padding-right: 40px;
`;

class Login extends React.Component {
  state = {
    redirectToReferrer: false,
  };

  constructor(props) {
    super(props);

    this.email = null;
    this.password = null;
  }

  login = e => {
    this.props.onLogIn(
      this.email.value,
      this.password.value,
      this.props.firebase
    );
    e.preventDefault();
  };

  logout = e => {
    this.props.onLogOut(this.props.firebase);
    e.preventDefault();
  };

  render() {
    return (
      <LoginWrapper>
        <Form horizontal onSubmit={this.login}>
          <FormGroup controlId="formHorizontalEmail">
            <Col componentClass={ControlLabel} sm={2}>
              Email
            </Col>
            <Col sm={4}>
              <FormControl
                type="email"
                placeholder="Email"
                inputRef={ref => {
                  this.email = ref;
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup controlId="formHorizontalPassword">
            <Col componentClass={ControlLabel} sm={2}>
              Password
            </Col>
            <Col sm={4}>
              <FormControl
                type="password"
                placeholder="Password"
                inputRef={ref => {
                  this.password = ref;
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup>
            <Col smOffset={2} sm={4}>
              <Button type="submit" bsStyle="primary">
                Sign in
              </Button>
            </Col>
          </FormGroup>
        </Form>
        {this.props.logInError ? (
          <Col smOffset={2} sm={4}>
            <Alert bsStyle="danger">{this.props.logInError.message}</Alert>
          </Col>
        ) : null}
      </LoginWrapper>
    );
  }
}

export default connect(
  ({
    firebase: { authError },
    local: {
      auth: { isLoggingIn, logInError, redirect },
    },
  }) => ({
    authError,
    isLoggingIn,
    logInError,
    redirectToReferrer: redirect,
  }),
  {
    onLogIn: (email, password, firebase) => login(email, password, firebase),
    onLogOut: firebase => logout(firebase),
  }
)(firebaseConnect()(Login));
