import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import { Router, Route, Redirect } from 'react-router-dom';
import { history } from './utils';
import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import * as firebase from 'firebase';
import {
  reactReduxFirebase,
  firebaseStateReducer,
  getFirebase,
} from 'react-redux-firebase';
import {
  userIsAuthenticatedRedir,
  userIsNotAuthenticatedRedir,
} from './components/auth';
import { toast } from 'react-toastify';
import get from 'lodash.get';
import 'babel-polyfill';
import { fetchData } from './actions';
import local from './reducers';
import AppComponent from './App';
import LoginComponent from './components/Login';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';
import config from './config.json';

const environment = config.environments[process.env.REACT_APP_ENVIRONMENT];

const firebaseConfig = {
  userProfile: 'users',
  apiKey: environment.FIREBASE_API_KEY,
  authDomain: environment.FIREBASE_AUTH_DOMAIN,
  databaseURL: environment.FIREBASE_DATABASE_URL,
};

/*
 Clear data from local storage
 This loses the state between browser refreshes but gets around compatabilty
 problems.
 
 TODO - replace with a more robust version based update.
*/
localStorage.removeItem('persist:root');

// redux-persist config
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['local'],
};

firebase.initializeApp(firebaseConfig);

const rootReducer = combineReducers({
  firebase: firebaseStateReducer,
  local,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const createStoreWithFirebase = compose(
  reactReduxFirebase(firebase, { userProfile: 'users' })
)(createStore);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store;

//** Fetch contents of data file only if configured to do so */
if (config.data === 'file') {
  store = createStore(
    combineReducers({ local }),
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
  store.dispatch(fetchData());
} else {
  store = createStoreWithFirebase(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(thunkMiddleware.withExtraArgument(getFirebase))
    )
  );
}

const persistor = persistStore(store);

let lastTimestamp;
let lastDashboard;

// TODO: Test this still works
//** Display toast if timestamp exists and has increased since last store update */
if (config.data !== 'file') {
  store.subscribe(() => {
    const state = store.getState();

    const timestamp = get(
      state.firebase.data,
      `${state.local.source}.Source.TimeStamp`
    );
    const username = get(
      state.firebase.data,
      `${state.local.source}.Source.UserName`
    );

    if (timestamp !== undefined) {
      if (
        timestamp > lastTimestamp &&
        state.local.dashboard === lastDashboard
      ) {
        toast.info(<p>{username} just updated this dashboard</p>);
      }

      lastTimestamp = timestamp;
      lastDashboard = state.local.dashboard;
    }
  });
}

let Login = null;
let App = null;

/** Wrap top-level components with redirects if using authentication */
if (environment.ENABLE_AUTH) {
  Login = userIsNotAuthenticatedRedir(LoginComponent);
  App = userIsAuthenticatedRedir(AppComponent);
} else {
  Login = LoginComponent;
  App = AppComponent;
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <React.Fragment>
          <Route
            exact
            path="/"
            render={() => (
              <Redirect to={'/dashboard/' + environment.dashboards[0]} />
            )}
          />
          <Route path="/login" component={Login} />
          <Route
            path="/dashboard/:dashboardId"
            render={rest => <App {...rest} />}
          />
        </React.Fragment>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
