import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import styled from 'styled-components';

const RevertButtonGroup = styled.div`
  margin-top: 40px;
`;

class AlertDismissable extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      buttonClicked: false,
    };

    this.revertModel = this.revertModel.bind(this);
  }

  revertModel() {
    this.setState({ buttonClicked: true });
    this.props.revertModel(this.props.data);
  }

  renderErrors() {
    if (this.props && this.props._canvas && this.props._canvas.errors) {
      const errors = this.props._canvas.errors.map((error, i) => {
        return (
          <p key={i}>
            <strong>Error message</strong>: {error}
          </p>
        );
      });
      return <div>{errors}</div>;
    }
  }

  render() {
    if (this.props.show) {
      return (
        <Alert bsStyle="danger">
          <h4>There are errors in the model</h4>
          {this.renderErrors()}
          <div>
            <RevertButtonGroup>
              <Button onClick={this.revertModel} bsStyle="danger">
                {this.state.buttonClicked ? 'Reverting...' : 'Revert the model'}
              </Button>
            </RevertButtonGroup>
          </div>
        </Alert>
      );
    }
    return null;
  }
}

AlertDismissable.defaultProps = {
  show: false,
};

export default AlertDismissable;
