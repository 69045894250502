import React from 'react';
import Loadable from 'react-loadable';
import Loader from './Loader';
import Alert from './Alert';

const FundingForecast = Loadable({
  loader: () => import ('../dashboards/FundingForecast/Dashboard'),
  loading: () => <Loader/>
});

const ESG = Loadable({
  loader: () => import ('../dashboards/ESG/Dashboard'),
  loading: () => <Loader/>
});

const InvestibleBenchmark = Loadable({
  loader: () => import ('../dashboards/InvestibleBenchmark/Dashboard'),
  loading: () => <Loader/>
});

const CashflowCoverage = Loadable({
  loader: () => import ('../dashboards/CashflowCoverage/Dashboard'),
  loading: () => <Loader/>
});

const Allenbridge = Loadable({
  loader: () => import ('../dashboards/Allenbridge/Dashboard'),
  loading: () => <Loader/>
});

const AllenbridgeSimple = Loadable({
  loader: () => import ('../dashboards/AllenbridgeSimple/Dashboard'),
  loading: () => <Loader/>
});

const BCSSSJourneyPlan = Loadable({
  loader: () => import ('../dashboards/BCSSSJourneyPlan/Dashboard'),
  loading: () => <Loader/>
});

const WaterfallChart = Loadable({
  loader: () => import ('../dashboards/WaterfallChart/Dashboard'),
  loading: () => <Loader/>
});

const AccountConsolidate = Loadable({
  loader: () => import ('../dashboards/AccountConsolidate/Dashboard'),
  loading: () => <Loader/>
});

const PACTAccountConsolidate = Loadable({
  loader: () => import ('../dashboards/PACTAccountConsolidate/Dashboard'),
  loading: () => <Loader/>
});

const CashflowMapping = Loadable({
  loader: () => import ('../dashboards/CashflowMapping/Dashboard'),
  loading: () => <Loader/>
});

const CDIExplorer = Loadable({
  loader: () => import ('../dashboards/CDIExplorer/Dashboard'),
  loading: () => <Loader/>
});

const Affirm = Loadable({
  loader: () => import ('../dashboards/Affirm/Dashboard'),
  loading: () => <Loader/>
});

const components = {
  ESG,
  InvestibleBenchmark,
  FundingForecast,
  CashflowCoverage,
  Allenbridge,
  AllenbridgeSimple,
  BCSSSJourneyPlan,
  WaterfallChart,
  AccountConsolidate,
  PACTAccountConsolidate,
  CashflowMapping,
  CDIExplorer,
  Affirm
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({error: error, errorInfo: errorInfo});
  }

  render() {
    if (this.state.errorInfo) {
      return (<Alert
        title="Something went wrong"
        details={this.state.error && this
        .state
        .error
        .toString()}
        stackTrace={this.state.errorInfo.componentStack}/>);
    }

    if (this.props.error.isError) {
      console.error('Could not fetch data from %s', this.props.error.databaseUrl);
      return (<Alert
        title={`Could not fetch data from ${this.props.error.databaseUrl}`}
        details={this.props.error.message}/>);
    }

    const Board = components[this.props.component];

    if (Board === undefined) {
      const err = `Could not find ${this.props.component} within 
      components`;
      throw err;
    }

    return (<Board
      data={this.props.data}
      triggersEnabled={this.props.triggersEnabled}
      isLoading={this.props.isLoading}
      onUpdateParameter={this.props.onUpdateParameter}/>);
  }
}

export default Dashboard;
