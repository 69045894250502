import React from 'react';
import { connect } from 'react-redux';
import { selectTask } from '../actions';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment';
import { colors } from '../utils';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import './TaskHistory.css';

const TaskFooter = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Header = styled.span`
  float: left;
  font-weight: 800;
`;

const Title = props => {
  if (props.restrictedMode) {
    return (
      <Modal.Title>
        Task History - This feature is not activated. <br /> Contact us for a
        full trial.
      </Modal.Title>
    );
  }

  return (
    <Modal.Title>
      Task History - this feature is under construction and not active.
    </Modal.Title>
  );
};

class TaskHistory extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      selectedTask: {},
    };

    this.onSelectTask = this.onSelectTask.bind(this);
    this.selectTask = this.selectTask.bind(this);
  }

  onSelectTask(taskId) {
    const selectedTask = this.props.tasks.find(t => {
      return t.id === taskId;
    });
    this.setState({ selectedTask: selectedTask });
  }

  selectTask() {
    this.props.onSelectTask(this.state.selectedTask);
    this.setState({ selectedTask: {} });
    this.props.closeTaskHistory();
  }

  render() {
    const columns = [
      {
        Header: () => <Header>Label</Header>,
        accessor: 'label',
        minWidth: 40,
      },
      {
        Header: () => <Header>Task</Header>,
        accessor: 'task',
        minWidth: 200,
      },
      {
        Header: () => <Header>Updated By</Header>,
        accessor: 'UpdatedBy',
        minWidth: 60,
      },
      {
        Header: () => <Header>On</Header>,
        accessor: task => {
          return moment(task.timestamp).format('D/M/YYYY H:mm');
        },
        id: 'On',
        minWidth: 75,
      },
      {
        Header: () => <Header>Dashboards Affected</Header>,
        accessor: 'DashboardsUpdated',
        minWidth: 150,
      },
      {
        accessor: 'selected',
        show: false,
      },
      {
        accessor: 'id',
        show: false,
      },
    ];

    return (
      <Modal
        show={this.props.showTaskHistory}
        onHide={this.props.closeTaskHistory}
        bsSize="large"
      >
        <Modal.Header closeButton>
          <Title restrictedMode={this.props.restrictedMode} />
        </Modal.Header>
        <Modal.Body>
          <ReactTable
            data={this.props.tasks}
            columns={columns}
            showPagination={false}
            style={{
              height: '400px',
            }}
            className="taskHistory"
            getTrProps={(state, rowInfo, column) => {
              if (rowInfo && rowInfo.row.id === this.state.selectedTask.id) {
                return {
                  style: {
                    background: colors('mustard'),
                  },
                };
              }

              return {
                style: {
                  background:
                    rowInfo && rowInfo.row.selected ? colors('cyan') : 'white',
                },
              };
            }}
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: (e, handleOriginal) => {
                  this.onSelectTask(rowInfo.row.id);
                  if (handleOriginal) {
                    handleOriginal();
                  }
                },
              };
            }}
          />

          <TaskFooter>
            <Button
              type="submit"
              bsStyle="primary"
              onClick={() => this.selectTask()}
            >
              Select Task
            </Button>
          </TaskFooter>
        </Modal.Body>
      </Modal>
    );
  }
}

TaskHistory.defaultProps = {
  showTaskHistory: true,
  tasks: [{}],
};

const mapDispatchToProps = {
  onSelectTask: task => selectTask(task),
};

export default connect(null, mapDispatchToProps)(TaskHistory);
