import React from 'react';
import styled from 'styled-components';

const Details = styled.details`
  white-space: pre-wrap;
`;

export default ({ title, details, stackTrace }) => {
  return (
    <div className="alert alert-danger" role="alert">
      <p>
        <strong>Oh snap!</strong> {title}
      </p>
      <Details>
        <p>{details}</p>
        {stackTrace ? (
          <div>
            <br />
            <p>{stackTrace}</p>
          </div>
        ) : null}
      </Details>
    </div>
  );
};
