import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import { toast, ToastContainer } from 'react-toastify';
import get from 'lodash.get';
import styled from 'styled-components';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import {
  toggleTriggers,
  logout,
  updateParameter,
  redirect,
  updateDashboard,
  updateSource,
  revertModel,
} from './actions';
import Alert from './components/Alert';
import config from './config.json';

const gaTrackingID =
  config.environments[process.env.REACT_APP_ENVIRONMENT].GA_TRACKING_ID;

ReactGA.initialize(gaTrackingID, { debug: false });

const AppWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

class App extends React.Component {
  componentDidUpdate(prevProps) {
    const dashboardId = this.props.match.params.dashboardId;

    if (
      dashboardId !== prevProps.match.params.dashboardId ||
      dashboardId !== this.props.currentDashboard
    ) {
      this.props.onUpdateDashboard(dashboardId);
      this.props.onUpdateSource(dashboardId);
    }
  }

  render() {
    const environment = config.environments[process.env.REACT_APP_ENVIRONMENT];
    const dashboardId = this.props.match.params.dashboardId;

    let validDashboards;

    if (this.props.profile.dashboards !== undefined) {
      validDashboards = environment.dashboards.filter(d =>
        this.props.profile.dashboards.includes(d)
      );
    } else {
      validDashboards = environment.dashboards;
    }

    if (
      this.props.profile.dashboards !== undefined &&
      environment.dashboards.includes(dashboardId) &&
      !this.props.profile.dashboards.includes(dashboardId)
    ) {
      this.props.onRedirect(`/dashboard/${validDashboards[0]}`);
      return null;
    }

    if (!validDashboards.includes(dashboardId)) {
      return (
        <Alert
          title={`Could not find dashboard with name: ${dashboardId}`}
          details={`Valid dashboard names are are: ${environment.dashboards}`}
        />
      );
    }

    const options = this.props.options.filter(option =>
      validDashboards.includes(option.value)
    );

    return (
      <AppWrapper>
        <Header
          dashboard={this.props.label || 'Unknown'}
          dataSource={this.props.dataSource}
          data={this.props.data}
          parameters={this.props.parameters}
          tasks={this.props.tasks}
          _canvas={this.props._canvas}
          options={options}
          lastUpdated={this.props.lastUpdated}
          updatedBy={this.props.updatedBy}
          user={this.props.user}
          version={process.env.REACT_APP_VERSION}
          onToggleTriggers={this.props.onToggleTriggers}
          triggersEnabled={this.props.triggersEnabled}
          enableAuth={this.props.enableAuth}
          onLogOut={() => this.props.onLogOut(this.props.firebase)}
          onUpdateParameter={(key, value) =>
            this.props.onUpdateParameter(key, value, this.props.dataSource)
          }
          revertModel={this.props.onRevertModel}
          enablePopup={this.props.enablePopup}
          states={this.props.states}
          restrictedMode={environment.RESTRICTED_MODE}
        />
        <Dashboard
          component={config.dashboards[dashboardId].component}
          isLoading={this.props.isLoading}
          error={this.props.error}
          data={this.props.data}
          triggersEnabled={this.props.triggersEnabled}
        />
        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
      </AppWrapper>
    );
  }
}

const mapStateToProps = (state, props) => {
  const timestamp = get(props.data, 'Source.TimeStamp');
  const updatedBy = get(props.data, 'Source.UserName', 'Unknown');

  const isLoading = !isLoaded(props.data) || props.data === undefined;

  let error = props.error;

  if (props.data === null && !isLoading) {
    error = {
      isError: true,
      message: `Tried fetching data from key with value ${
        props.dataKey
      }. Check this key is present.`,
      databaseUrl:
        config.environments[process.env.REACT_APP_ENVIRONMENT]
          .FIREBASE_DATABASE_URL,
    };
  } else if (!isLoading) {
    const missingSubKeys = props.subKeys.filter(
      k => props.data[k] === undefined
    );

    if (missingSubKeys.length > 0) {
      error = {
        isError: true,
        message: `Expected the following subkey(s) to be present: ${
          props.subKeys
        }
Instead found: ${Object.keys(props.data)}`,
        databaseUrl:
          config.environments[process.env.REACT_APP_ENVIRONMENT]
            .FIREBASE_DATABASE_URL,
      };
    }
  }

  const user =
    props.email !== undefined
      ? props.email
      : state.local.auth.user && state.local.auth.user.email;

  const currentDashboard = state.local.dashboard;

  let parameters = [];
  let tasks;

  if (
    !isLoading &&
    props.data !== null &&
    props.data.hasOwnProperty('DashboardInfo') &&
    props.data.DashboardInfo.hasOwnProperty('TunableProperties')
  ) {
    const tunableProperties = props.data.DashboardInfo.TunableProperties;
    parameters = tunableProperties.map(p => ({
      name: p.name,
      label: p.label,
      value: p.value,
      range: p.range,
      type: p.type,
      size: p.size,
      source: p.source,
      tab: p.tab,
      task: p.task,
    }));

    tasks = [...new Set(parameters.map(x => x.task))];
  }

  if (
    !isLoading &&
    props.data !== null &&
    props.data.hasOwnProperty('DashboardInfo') &&
    props.data.DashboardInfo.hasOwnProperty('Tunables')
  ) {
    const tunableProperties = Object.values(props.data.DashboardInfo.Tunables);
    parameters = tunableProperties.map(p => ({
      name: p.name,
      label: p.label,
      value: p.value,
      range: p.range,
      type: p.type,
      size: p.size,
      tab: p.tab,
      task: p.task,
    }));

    tasks = [...new Set(parameters.map(x => x.task))];
  }

  return {
    isLoading,
    error,
    user,
    currentDashboard,
    data: props.data,
    dataSource: props.dataSource,
    options: state.local.config,
    parameters,
    tasks,
    states: props.states,
    lastUpdated: timestamp,
    updatedBy: updatedBy,
    triggersEnabled: state.local.triggersEnabled,
    enableAuth: state.local.enableAuth,
    enablePopup: state.local.enablePopup,
    profile: state.firebase.profile,
    _canvas: props._canvas,
  };
};

const mapDispatchToProps = {
  onToggleTriggers: () => toggleTriggers(),
  onRedirect: to => redirect(to),
  onLogOut: firebase => logout(firebase),
  onUpdateParameter: (key, value, dashboard) =>
    updateParameter(key, value, dashboard),
  onUpdateDashboard: updateDashboard,
  onUpdateSource: updateSource,
  onRevertModel: revertModel,
};

const wrappedApp = connect(mapStateToProps, mapDispatchToProps)(App);

//** Watch the Firebase key in props.dataKey for changes */
const firebaseWrappedComponent = firebaseConnect(props => {
  return props.error.isError ? [] : [props.dataKey, '_canvas', 'states'];
})(wrappedApp);

//** Map the data in Firebase key to data */
const firebaseApp = connect((state, props) => {
  const dashboardConfig = config.dashboards[props.match.params.dashboardId];

  if (dashboardConfig) {
    const dataKey = dashboardConfig.source;
    const subKeys = dashboardConfig.keys;
    const label = dashboardConfig.label;

    return {
      dataKey,
      subKeys,
      label,
      dataSource: dataKey,
      data: state.firebase.data[dataKey],
      _canvas: state.firebase.data._canvas,
      states: state.firebase.data.states,
      error: { isError: false },
      email: state.firebase.auth.email,
    };
  } else {
    return {
      error: {
        isError: true,
        message: `Invalid dashboard id: ${props.match.params.dashboardId}`,
        databaseUrl:
          config.environments[process.env.REACT_APP_ENVIRONMENT]
            .FIREBASE_DATABASE_URL,
      },
    };
  }
})(firebaseWrappedComponent);

const fileApp = connect((state, props) => {
  const dashboardConfig = config.dashboards[props.match.params.dashboardId];

  const dataKey = dashboardConfig.source;
  const subKeys = dashboardConfig.keys;
  const label = dashboardConfig.label;

  if (state.local.data[dataKey] === undefined && !state.local.isLoading) {
    return {
      data: undefined,
      error: {
        isError: true,
        message: 'Could not find data in file',
        databaseUrl: 'data.json',
      },
    };
  }

  return {
    isLoading: state.local.isLoading,
    dataKey,
    subKeys,
    label,
    data: state.local.data[dataKey],
    error: { isError: false },
  };
})(wrappedApp);

export default (config.data === 'file' ? fileApp : firebaseApp);
