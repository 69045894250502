import uniq from 'lodash.uniq';
import { history } from '../utils';

export const SELECT = 'SELECT';
export const ADD_SCENARIO = 'ADD_SCENARIO';
export const REMOVE_SCENARIO = 'REMOVE_SCENARIO';
export const TOGGLE_SCENARIO = 'TOGGLE_SCENARIO';
export const UPDATE_SCENARIO_NAME = 'UPDATE_SCENARIO_NAME';
export const REQUEST_DATA = 'REQUEST_DATA';
export const RECEIVE_DATA = 'RECIEVE_DATA';
export const LOG_IN = 'LOG_IN';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';
export const LOG_OUT = 'LOG_OUT';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAILURE = 'LOG_OUT_FAILURE';
export const TOGGLE_TRIGGERS = 'TOGGLE_TRIGGERS';
export const SHOW_MAXIMIZED_CHART = 'SHOW_MAXIMIZED_CHART';
export const HIDE_MAXIMIZED_CHART = 'HIDE_MAXIMIZED_CHART';
export const SEND_MODEL_PARAMETER = 'SEND_MODEL_PARAMETER';
export const UPDATE_LOCAL_DASHBOARD = 'UPDATE_LOCAL_DASHBOARD';
export const UPDATE_DASHBOARD = 'UPDATE_DASHBOARD';
export const UPDATE_SOURCE = 'UPDATE_SOURCE';

export function select(key, value, dashboard = 'UNKNOWN') {
  return { type: SELECT, key, value, dashboard };
}

export function addScenario(content, dashboard = 'UNKNOWN') {
  return {
    type: ADD_SCENARIO,
    id: content.id,
    content,
    dashboard,
  };
}

export function removeScenario(id, dashboard = 'UNKNOWN') {
  return {
    type: REMOVE_SCENARIO,
    id: id,
    dashboard,
  };
}

export function toggleScenario(id, dashboard = 'UNKNOWN') {
  return {
    type: TOGGLE_SCENARIO,
    id: id,
    dashboard,
  };
}

export function updateScenarioName(id, content, dashboard = 'UNKNOWN') {
  return {
    type: UPDATE_SCENARIO_NAME,
    id: id,
    content: content,
    dashboard,
  };
}

export function redirect(to) {
  return function() {
    setImmediate(() => history.push(to));
  };
}

export function requestData() {
  return { type: REQUEST_DATA };
}

export function receiveData(data) {
  return { type: RECEIVE_DATA, data: data };
}

export function fetchData() {
  return function(dispatch) {
    dispatch(requestData());

    return fetch(`../data.json`)
      .then(
        response => response.json(),
        error => console.log('An error occured.', error)
      )
      .then(json => dispatch(receiveData(json)));
  };
}

export function logIn(email, password) {
  return { type: LOG_IN, email: email, password: password };
}

export function logInSuccess(email) {
  return { type: LOG_IN_SUCCESS, email: email };
}

export function logInFailure(error) {
  return { type: LOG_IN_FAILURE, error: error };
}

export function logOut() {
  return { type: LOG_OUT };
}

export function logOutSuccess() {
  return { type: LOG_OUT_SUCCESS };
}

export function logOutFailure(error) {
  return { type: LOG_IN_FAILURE, error: error };
}

export function login(email, password, firebase) {
  return function(dispatch) {
    dispatch(logIn(email, password, firebase));

    return firebase
      .login({ email: email, password: password })
      .then(() => {
        console.log('Succesfully logged in');
        dispatch(logInSuccess(email));
      })
      .catch(error => {
        console.log('there was an error', error);
        dispatch(logInFailure(error));
      });
  };
}

export function logout(firebase) {
  return function(dispatch) {
    dispatch(logOut());

    return firebase
      .logout()
      .then(() => {
        console.log('Succesfully logged out');
        dispatch(logOutSuccess());
      })
      .catch(error => {
        console.log('there was an error', error);
        dispatch(logOutFailure(error));
      });
  };
}

export function updateDashboard(dashboard) {
  return { type: UPDATE_DASHBOARD, dashboard };
}

export function updateSource(source) {
  return { type: UPDATE_SOURCE, source };
}

export function toggleTriggers() {
  return { type: TOGGLE_TRIGGERS };
}

export function showMaximizedChart(chart) {
  return { type: SHOW_MAXIMIZED_CHART, chart: chart };
}

export function hideMaximizedChart() {
  return { type: HIDE_MAXIMIZED_CHART };
}

export function sendModelParameter(key, value, dashboard) {
  return {
    type: SEND_MODEL_PARAMETER,
    key: key,
    value: value,
    dashboard: dashboard,
  };
}

// TODO: Error if dashboard is not provided
export function updateParameter(parameters, source, dashboard = 'UNKNOWN') {
  return function(dispatch, getState, getFirebase) {
    dispatch(sendModelParameter(parameters, dashboard));

    const firebase = getFirebase();

    const folderName = source.FolderName;
    const sourceBlock = source.SourceBlock;
    const stateFile = source.StateFile;

    const tunableSourceBlocks = uniq(parameters.map(p => p.source));
    const update = {};

    tunableSourceBlocks.forEach(function(x) {
      const paramObj = {};

      parameters.filter(p => p.source === x).forEach(function(y) {
        paramObj[y.name] = y.value;
      });

      update[x] = paramObj;
    });

    const callback = {
      FolderName: folderName,
      SourceBlock: sourceBlock,
      StateFile: stateFile,
      reference: Date.now(),
      update,
    };

    return firebase.set(`_studio/callback/`, callback);
  };
}

export function selectTask(task) {
  return function(dispatch, getState, getFirebase) {
    const firebase = getFirebase();

    const folderName = task.FolderName;
    const stateFile = task.StateFile;

    const update = task;

    const callback = {
      FolderName: folderName,
      StateFile: stateFile,
      reference: Date.now(),
      update,
    };

    return firebase.set(`_studio/callback/`, callback);
  };
}

export function revertModel(source) {
  return function(dispatch, getState, getFirebase) {
    const firebase = getFirebase();

    const folderName = source.FolderName;
    const sourceBlock = source.SourceBlock;
    const stateFile = source.StateFile;

    const callback = {
      FolderName: folderName,
      SourceBlock: sourceBlock,
      StateFile: stateFile,
      reference: Date.now(),
      revert: 1,
    };

    return firebase.set(`_studio/callback/`, callback);
  };
}
