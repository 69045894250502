import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import {
  Row,
  Col,
  Glyphicon,
  Checkbox,
  InputGroup,
  MenuItem,
  Navbar,
  Nav,
  NavDropdown,
  NavItem,
  Modal,
  Button,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Well,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import styled, { keyframes } from 'styled-components';
import AlertDismissable from './AlertDismissable';
import TaskHistory from './TaskHistory';
import { colors } from '../utils';

const POPUP_DELAY = 25000;

// TODO: Remove !important
const StyledNavbar = styled(Navbar)`
  margin-bottom: 0px !important;
`;

const StyledNavbarLink = styled(Navbar.Link)`
  color: white;
  cursor: pointer;
`;

const TaskLink = styled.a`
  color: black;
  cursor: pointer;
  font-size: 24px;
`;

const RedDot = styled.span`
  &:before {
    content: '\\25cf';
    font-size: 34px;
    color: ${colors('pms32')};
  }
`;

const GreenDot = styled.span`
  &:before {
    content: '\\25cf';
    font-size: 34px;
    color: ${colors('pms376')};
  }
`;

const Pulsate = keyframes`
  0% {transform: scale(0.1, 0.1); opacity: 0.3;}
  50% {opacity: 1.0;}
  100% {transform: scale(1.2, 1.2); opacity: 0.3;}
`;

const OrangeDot = styled.span`
  &:before {
    content: '\\25cf';
    font-size: 34px;
    color: ${colors('pms21')};
    animation: ${Pulsate} 1s ease-out infinite;
  }
`;

const ModelParamText = props => {
  if (props.restrictedMode) {
    return <p>This feature is not activated. Contact us for a full trial.</p>;
  }

  return (
    <p>
      Changing any of these model parameters will trigger the model to
      recalculate on the <strong>server</strong>. This may take some time.
    </p>
  );
};

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showConfig: false,
      showTasks: true,
      showTaskHistory: false,
      selectedTask: 'What do you want to do?',
      parameters: {},
      errors: false,
      reopenWindow: false,
    };

    this.parameters = {};
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.openConfig = this.openConfig.bind(this);
    this.closeConfig = this.closeConfig.bind(this);
    this.openTaskHistory = this.openTaskHistory.bind(this);
    this.closeTaskHistory = this.closeTaskHistory.bind(this);
    this.openError = this.openError.bind(this);
    this.closeError = this.closeError.bind(this);
    this.selectTask = this.selectTask.bind(this);
    this.submitFeedback = this.submitFeedback.bind(this);
    this.updateParameter = this.updateParameter.bind(this);
    this.submitModelParameters = this.submitModelParameters.bind(this);
    this.onMultiStringChanged = this.onMultiStringChanged.bind(this);
  }

  close() {
    this.setState({ showModal: false });
  }

  open() {
    this.setState({ showModal: true });
  }

  closeConfig() {
    this.setState({
      showConfig: false,
      showTasks: true,
      selectedTask: 'What do you want to do?',
    });
  }

  openConfig() {
    this.setState({ showConfig: true });
  }

  closeTaskHistory() {
    this.setState({
      showTaskHistory: false,
    });
  }

  openTaskHistory() {
    this.setState({ showTaskHistory: true });
  }

  closeError() {
    this.setState({
      errors: false,
    });
  }

  openError() {
    this.setState({ errors: true });
  }

  selectTask(taskId) {
    this.setState({ showTasks: false, selectedTask: taskId });
  }

  updateParameter(key, value) {
    this.setState({ parameters: { ...this.state.parameters, [key]: value } });
  }

  submitFeedback = e => {
    e.preventDefault();

    const feedback = {
      name: this.name.value,
      email: this.email.value,
      content: this.content.value,
    };

    const API_ENDPOINT =
      'https://financial-canvas-studio.firebaseapp.com/sendFeedbackEmail';

    const xhr = new XMLHttpRequest();
    xhr.open('POST', API_ENDPOINT, true);
    xhr.setRequestHeader('Content-type', 'application/json;charset=UTF-8');
    xhr.send(JSON.stringify(feedback));

    setTimeout(this.close, 10);
  };

  onMultiStringChanged(e) {
    e.preventDefault();

    if (e.target.id === 'ScenarioInputControl') {
      this.setState({ reopenWindow: true }, () => this.submitModelParameters());
    }
  }

  submitModelParameters = e => {
    if (e) {
      e.preventDefault();
    }

    let parameters = [];

    for (const [key, value] of Object.entries(this.parameters)) {
      const param = this.props.parameters.find(p => p.name === key);

      // ignore params we don't have data for as we are not updating them
      if (value !== null && param) {
        switch (param.type) {
          case 'string':
            if (param.size !== 'multi') {
              parameters.push({
                name: key,
                value: value.value,
                source: param.source,
              });
            } else {
              const options = value.options;
              const selectedOptions = [];

              for (let i = 0, l = options.length; i < l; i++) {
                if (options[i].selected) {
                  selectedOptions.push(options[i].value);
                }
              }

              parameters.push({
                name: key,
                value: selectedOptions,
                source: param.source,
              });
            }
            break;
          case 'numeric':
          case 'currency':
            parameters.push({
              name: key,
              value: +value.value,
              source: param.source,
            });
            break;
          case 'logical':
            parameters.push({
              name: key,
              value: value.checked,
              source: param.source,
            });
            break;
          case 'percentage':
            parameters.push({
              name: key,
              value: +value.value / 100,
              source: param.source,
            });
            break;
          case 'datenum':
            parameters.push({
              name: key,
              value: this.state.parameters.hasOwnProperty(key)
                ? this.state.parameters[key]
                : value,
              source: param.source,
            });
            break;
          case 'year':
            parameters.push({
              name: key,
              value: +value.value,
              source: param.source,
            });
            break;
          default:
            parameters.push({
              name: key,
              value: value.value,
              source: param.source,
            });
        }
      }
    }

    this.props.onUpdateParameter(
      parameters,
      this.props.data.Source,
      this.props.dataSource
    );

    if (!this.state.reopenWindow) {
      setTimeout(this.closeConfig, 10);
      this.setState({
        selectedTask: 'What do you want to do?',
        showTasks: true,
      });
    } else {
      this.setState({ showConfig: false });
      setTimeout(this.openConfig, 3000);
    }

    this.setState({ reopenWindow: false });
  };

  componentWillMount() {
    if (
      localStorage.getItem('studio:popState') !== 'shown' &&
      this.props.enablePopup
    ) {
      setTimeout(() => this.open(), POPUP_DELAY);
      localStorage.setItem('studio:popState', 'shown');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props._canvas.errors !== prevProps._canvas.errors) {
      if (this.props._canvas.errors === undefined) {
        this.closeError();
        toast.info('Model has been reverted and is ready to use', {
          autoClose: 10000,
        });
      } else {
        this.openError();
      }
    }
  }

  render() {
    /*
    Grab the listening timestamp from _canvas. If it is older than 1 minute
    display red dot, if less than one minute display green dot. If listening property 
    does not exist show a white empty dot. If the working property is true show an orange
    dot.

    listening can be: on, off, working
    */

    let listening;
    if (this.props._canvas.working) {
      listening = 'working';
    } else if (this.props._canvas.listening) {
      const timestamp = moment.utc(this.props._canvas.listening);
      const now = moment.utc(Date.now());
      listening = now.diff(timestamp, 'minute') >= 1 ? 'off' : 'on';
    } else {
      listening = 'off';
    }

    const tooltip = (
      <Tooltip id="tooltip">
        {
          {
            on: 'The listener is running.',
            off: 'The listener is not running.',
            working: 'The listener is working.',
          }[listening]
        }
      </Tooltip>
    );

    const publicFeedback = (
      <Modal show={this.state.showModal} onHide={this.close}>
        <Modal.Header closeButton>
          <Modal.Title>Get in touch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Well>
            <p>Hi, this is Eddy from the Financial Canvas team.</p>
            <p>
              If you would like to see this dashboard for <strong>your</strong>{' '}
              analytics please enter your details below and we'll get back to
              you.
            </p>
          </Well>
          <Form onSubmit={this.submitFeedback}>
            <FormGroup controlId="formControlsText">
              <ControlLabel>Name</ControlLabel>
              <FormControl
                type="text"
                placeholder="Enter your name"
                inputRef={ref => {
                  this.name = ref;
                }}
              />
            </FormGroup>
            <FormGroup controlId="formControlsEmail">
              <ControlLabel>Email</ControlLabel>
              <FormControl
                type="email"
                placeholder="Enter your email address"
                inputRef={ref => {
                  this.email = ref;
                }}
              />
            </FormGroup>
            <FormGroup controlId="formControlsTextarea">
              <ControlLabel>Company</ControlLabel>
              <FormControl
                type="text"
                placeholder="Enter your company"
                inputRef={ref => {
                  this.content = ref;
                }}
              />
            </FormGroup>
            <Button bsStyle="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );

    const privateFeedback = (
      <Modal show={this.state.showModal} onHide={this.close}>
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.submitFeedback}>
            <FormGroup controlId="formControlsText">
              <ControlLabel>Name</ControlLabel>
              <FormControl
                type="text"
                placeholder="Enter your name"
                inputRef={ref => {
                  this.name = ref;
                }}
              />
            </FormGroup>
            <FormGroup controlId="formControlsEmail">
              <ControlLabel>Email</ControlLabel>
              <FormControl
                type="email"
                placeholder="Enter your email address"
                inputRef={ref => {
                  this.email = ref;
                }}
              />
            </FormGroup>
            <FormGroup controlId="formControlsTextarea">
              <ControlLabel>Let us know how we're doing</ControlLabel>
              <FormControl
                componentClass="textarea"
                placeholder="Enter your feedback here..."
                inputRef={ref => {
                  this.content = ref;
                }}
              />
            </FormGroup>
            <Button bsStyle="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );

    const configModal = (
      <Modal show={this.state.showConfig} onHide={this.closeConfig}>
        <Modal.Header closeButton>
          <Modal.Title>{this.state.selectedTask}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.tasks &&
            this.state.showTasks &&
            this.props.tasks.map((x, i) => {
              return (
                <Row key={i}>
                  <Col sm={12}>
                    <TaskLink onClick={this.selectTask.bind(this, x)}>
                      {x}
                    </TaskLink>
                  </Col>
                </Row>
              );
            })}
          <Form horizontal onSubmit={this.submitModelParameters}>
            {!this.state.showTasks && (
              <FormGroup key={'taskName'}>
                <Col componentClass={ControlLabel} sm={4}>
                  Task name
                </Col>
                <Col sm={8}>
                  <FormControl
                    type="text"
                    inputRef={ref => {
                      this.parameters['taskName'] = ref;
                    }}
                    placeholder={
                      'Give this task a name so you can refer back to it later'
                    }
                    autoFocus
                  />
                  {/* <HelpBlock>
                    This is a local name for your reference.
                  </HelpBlock> */}
                </Col>
              </FormGroup>
            )}
            {this.props.tasks &&
              this.props.tasks.map(x => {
                return this.props.parameters
                  .filter(y => y.task === x)
                  .map((p, i) => {
                    let formControl;
                    switch (p.type) {
                      case 'numeric':
                        formControl = (
                          <FormControl
                            type="number"
                            inputRef={ref => {
                              this.parameters[p.name] = ref;
                            }}
                            defaultValue={p.value}
                            step="any"
                          />
                        );
                        break;
                      case 'string':
                        if (p.range !== undefined) {
                          formControl = (
                            <FormControl
                              componentClass="select"
                              placeholder="select"
                              inputRef={ref => {
                                this.parameters[p.name] = ref;
                              }}
                              onChange={this.onMultiStringChanged}
                              multiple={p.size === 'multi'}
                              id={p.name}
                            >
                              {p.range.map((v, i) => {
                                const selected = p.value === v ? true : false;
                                return (
                                  <option key={i} value={v} selected={selected}>
                                    {v}
                                  </option>
                                );
                              })}
                            </FormControl>
                          );
                        } else {
                          formControl = (
                            <FormControl
                              type="text"
                              inputRef={ref => {
                                this.parameters[p.name] = ref;
                              }}
                              defaultValue={p.value}
                            />
                          );
                        }
                        break;
                      case 'logical':
                        formControl = (
                          <Checkbox
                            inputRef={ref => {
                              this.parameters[p.name] = ref;
                            }}
                            defaultChecked={p.value ? true : false}
                          />
                        );
                        break;
                      case 'percentage':
                        formControl = (
                          <InputGroup>
                            <FormControl
                              type="number"
                              inputRef={ref => {
                                this.parameters[p.name] = ref;
                              }}
                              defaultValue={p.value * 100}
                              step="any"
                            />
                            <InputGroup.Addon>%</InputGroup.Addon>
                          </InputGroup>
                        );
                        break;
                      case 'currency':
                        formControl = (
                          <InputGroup>
                            <InputGroup.Addon>£</InputGroup.Addon>
                            <FormControl
                              type="number"
                              inputRef={ref => {
                                this.parameters[p.name] = ref;
                              }}
                              defaultValue={p.value}
                            />
                          </InputGroup>
                        );
                        break;
                      case 'datenum':
                        const value = this.state.parameters.hasOwnProperty(
                          p.name
                        )
                          ? this.state.parameters[p.name]
                          : p.value;

                        this.parameters[p.name] = value;

                        formControl = (
                          <DatePicker
                            selected={moment(value)}
                            onChange={date =>
                              this.updateParameter(
                                p.name,
                                date.format('YYYY-MM-DD')
                              )
                            }
                          />
                        );
                        break;
                      case 'year':
                        formControl = (
                          <FormControl
                            type="number"
                            inputRef={ref => {
                              this.parameters[p.name] = ref;
                            }}
                            defaultValue={p.value}
                            step="1"
                          />
                        );
                        break;
                      case 'integerList':
                        formControl = (
                          <FormControl
                            type="text"
                            inputRef={ref => {
                              this.parameters[p.name] = ref;
                            }}
                            defaultValue={p.value}
                          />
                        );
                        break;
                      default:
                        formControl = <p>Unknown parameter type: {p.type}</p>;
                    }

                    if (this.state.selectedTask === p.task) {
                      return (
                        <FormGroup key={i}>
                          <Col componentClass={ControlLabel} sm={4}>
                            {p.label}
                          </Col>
                          <Col sm={8}>{formControl}</Col>
                        </FormGroup>
                      );
                    } else {
                      return null;
                    }
                  });
              })}

            {!this.state.showTasks && (
              <FormGroup>
                <Col smOffset={4} sm={8}>
                  <Button bsStyle="primary" type="submit">
                    Recalculate
                  </Button>
                </Col>
              </FormGroup>
            )}
          </Form>
          <hr />
          <ModelParamText restrictedMode={this.props.restrictedMode} />
        </Modal.Body>
      </Modal>
    );

    const errorModal = (
      <Modal show={this.state.errors}>
        <Modal.Body>
          {this.props &&
            this.props._canvas &&
            this.props._canvas.errors &&
            this.props.data &&
            this.props.data.Source && (
              <AlertDismissable
                _canvas={this.props._canvas}
                revertModel={this.props.revertModel}
                data={this.props.data.Source}
                show={this.state.errors}
              />
            )}
        </Modal.Body>
      </Modal>
    );

    return (
      <div>
        <StyledNavbar inverse collapseOnSelect staticTop>
          <Navbar.Header>
            <Navbar.Brand>
              <StyledNavbarLink href="http://www.financialcanvas.co.uk/">
                Financial Canvas Studio
              </StyledNavbarLink>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Navbar.Text>
              <StyledNavbarLink onClick={this.open}>
                Get in touch
              </StyledNavbarLink>
            </Navbar.Text>
            <Nav pullRight>
              <NavDropdown
                title={this.props.dashboard}
                id="dashboard-nav-dropdown"
              >
                {this.props.options.map(option => (
                  <LinkContainer
                    to={`/dashboard/${option.value}`}
                    key={option.value}
                  >
                    <MenuItem>{option.label}</MenuItem>
                  </LinkContainer>
                ))}
              </NavDropdown>
              <NavItem onClick={this.openTaskHistory}>Task History</NavItem>
              {this.props._canvas &&
              this.props._canvas.twoway &&
              this.props.parameters.length > 0 ? (
                <NavItem onClick={this.openConfig}>Model Settings</NavItem>
              ) : null}
              <NavItem>
                {
                  {
                    on: (
                      <OverlayTrigger placement="bottom" overlay={tooltip}>
                        <GreenDot />
                      </OverlayTrigger>
                    ),
                    off: (
                      <OverlayTrigger placement="bottom" overlay={tooltip}>
                        <RedDot />
                      </OverlayTrigger>
                    ),
                    working: (
                      <OverlayTrigger placement="bottom" overlay={tooltip}>
                        <OrangeDot />
                      </OverlayTrigger>
                    ),
                  }[listening]
                }
              </NavItem>
              <NavDropdown
                noCaret
                title={<Glyphicon glyph="info-sign" />}
                id="info-nav-dropdown"
              >
                <MenuItem header>Data source</MenuItem>
                <MenuItem>
                  <Glyphicon glyph="time" /> Last updated{' '}
                  {this.props.lastUpdated !== undefined ? (
                    <Moment fromNow date={moment.utc(this.props.lastUpdated)} />
                  ) : (
                    'Unknown'
                  )}
                </MenuItem>
                <MenuItem>
                  <Glyphicon glyph="user" /> By {this.props.updatedBy}
                </MenuItem>
                <MenuItem divider />
                <MenuItem header>About</MenuItem>
                <MenuItem>Version {this.props.version}</MenuItem>
              </NavDropdown>
              {this.props.enableAuth ? (
                <NavDropdown
                  noCaret
                  title={<Glyphicon glyph="user" />}
                  id="account-nav-dropdown"
                >
                  <MenuItem header>Account</MenuItem>
                  <MenuItem>
                    <Glyphicon glyph="user" /> {this.props.user}
                  </MenuItem>
                  <MenuItem divider />
                  <MenuItem onSelect={this.props.onLogOut}>Sign out</MenuItem>
                </NavDropdown>
              ) : null}
            </Nav>
          </Navbar.Collapse>
        </StyledNavbar>
        {this.props.enableAuth ? privateFeedback : publicFeedback}
        {configModal}
        {errorModal}
        <TaskHistory
          tasks={this.props.states ? this.props.states : null}
          showTaskHistory={this.state.showTaskHistory}
          closeTaskHistory={this.closeTaskHistory}
          restrictedMode={this.props.restrictedMode}
        />
      </div>
    );
  }
}

Header.defaultProps = {
  _canvas: {},
  states: [],
};

export default Header;
