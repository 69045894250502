import React from 'react';
import styled, { keyframes } from 'styled-components';

const load7 = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
`;

const Loader = styled.div`
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: ${load7} 1.8s infinite ease-in-out;

  color: #9d9d9d;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;

  :before,
  :after {
    content: '';
    position: absolute;
    top: 0;
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: ${load7} 1.8s infinite ease-in-out;
  }

  :before {
    left: -3.5em;
    animation-delay: -0.32s;
  }

  :after {
    left: 3.5em;
  }
`;

export default props => <Loader>Loading...</Loader>;
